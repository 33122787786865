<template>
  <div class="container">
    <div class="center">
      <div class="account">
        <div class="title">引领企业 , 数字转型</div>
        <div class="content">
          <!-- 微信扫码 -->
          <div class="wx-login">
            <div class="wx-login-content">
              <div class="wx-login-title">微信登录</div>
              <div class="wx-code"><div id="qrcode" ref="qrcode"></div></div>
              <div class="wx-login-reget" v-show="isReget">
                <a-button
                  class="wx-login-getbtn"
                  type="primary"
                  @click="reWxGetCode"
                  >点击刷新二维码</a-button
                >
              </div>
              <div class="wx-login-remark">请关注<span style="color:#000;">智赋云数字经济</span>公众号</div>
            </div>
          </div>
          <!-- 账号密码 -->
          <a-form
            :form="form"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
            @submit="handleSubmit"
            class="content_right"
          >
            <a-form-item label="">
              <a-input
                class="common-input"
                placeholder="请输入登录账号"
                v-decorator="[
                  'username',
                  { rules: [{ required: true, message: '请输入登录账号!' }] },
                ]"
              />
            </a-form-item>
            <a-form-item label="">
              <a-input-password
                class="common-input"
                type="password"
                placeholder="请输入登录密码"
                v-decorator="[
                  'password',
                  { rules: [{ required: true, message: '请输入登录密码!' }] },
                ]"
              />
            </a-form-item>
            <a-form-item label="" class="test-code">
              <div class="ccode">
                <a-input
                  class="common-input"
                  style="width: 159px"
                  placeholder="图形验证码"
                  @change="inputCodeChange"
                  v-decorator="[
                    'captcha',
                    {
                      rules: [
                        { required: true, message: '请输入图形验证码!' },
                        { validator: validateInputCode },
                      ],
                    },
                  ]"
                />
                <div class="pint">
                  <j-graphic-code
                    @success="generateCode"
                    ref="jgraphicCodeRef"
                    style="float: right"
                    remote
                  ></j-graphic-code>
                </div>
              </div>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 0 }">
              <a-button
                class="common-input btn"
                type="primary"
                html-type="submit"
                :loading="loginLoading"
              >
                登录
              </a-button>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24, offset: 0 }">
              <a-checkbox
                v-decorator="[
                  'agreement',
                  { rules: [{ required: true, message: '请勾选服务协议' }] },
                ]"
              >
                我已阅读并同意<router-link
                  target="_blank"
                  :to="{ path: '/contract' }"
                  >服务协议</router-link
                >
              </a-checkbox>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <!-- <div class="remark-number">©2020 备案号：蜀ICP备案20007130号</div> -->
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { mapActions } from "vuex";
import JGraphicCode from "@/components/JGraphicCode";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";

import { getWxCode, isSuccessCode, login } from "@/api/login";
import { checkBindWxMq } from "@/api/api";
export default {
  name: "Login",
  components: {
    JGraphicCode,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      isReget: false, // 二维码是否过期
      wxCodeTimer: null, // 二维码过期的时间计时器timer
      verifiedCode: "", // 请求获取的验证码的值
      inputCodeContent: "", // 输入的验证码的值
      intervalTimer: null, // 请求二维码是否
      wxCodeKey: "", // 验证二维码是否登录成功的参数key
      // loginCodeKey: "",
      loginParams: {
        // 验证码的值
        checkKey: "",
        loginType: "gig",
      },
      loginLoading: false, // 账号密码登录的loading
    };
  },
  created() {
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (token && token.length > 0) {
      this.$router.push("/rask");
      return;
    }
    this.handleCheck();
    // this.checkBindWxMq()
  },
  destroyed() {
    if (this.wxCodeTimer !== null) {
      clearTimeout(this.wxCodeTimer);
    }
    if (this.intervalTimer !== null) {
      clearInterval(this.intervalTimer);
    }
  },
  methods: {
    ...mapActions(["Login", "WxLogin", "Logout"]),
    // 获取二维码链接
    getCodeUrl() {
      getWxCode().then((res) => {
        let url = res.result.QRCode.url;
        this.wxCodeKey = res.result.key;
        this.makeQrcode(url);
      });
    },
    // 生成二维码
    makeQrcode(text) {
      if (this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      } else {
        this.qrcode = new QRCode("qrcode", {
          width: 262, // 设置宽度，单位像素
          height: 262, // 设置高度，单位像素
          text, // 设置二维码内容或跳转地址
        });
      }
    },
    // 点击二维码进入二维码页面
    handleCheck() {
      this.reWxGetCode();
    },
    // 校验扫描二维码是否成功
    validWxCodeSuccess() {
      this.intervalTimer = setInterval(() => {
        isSuccessCode(this.wxCodeKey).then((res) => {
          // console.log('检验是否成功',res)
          if (res.success) {
            clearInterval(this.intervalTimer);
            this.WxLogin(res).then(this.$router.push("/rask"));
          }
        });
      }, 1000);
    },

    // 重新获取
    reWxGetCode() {
      this.isReget = false;
      this.$nextTick(() => {
        this.getCodeUrl();
      });
      this.wxCodeTimer = setTimeout(() => {
        this.isReget = true;
        if (this.intervalTimer !== null) {
          clearInterval(this.intervalTimer);
        }
      }, 60000);
      this.validWxCodeSuccess();
    },

    // 点击获取验证码
    generateCode(value, checkKey) {
      this.loginParams.checkKey = checkKey;
      this.verifiedCode = value.toLowerCase();
    },
    // 输入的验证码与获取的验证码对比
    validateInputCode(rule, value, callback) {
      if (!value || this.verifiedCode == this.inputCodeContent) {
        callback();
      } else {
        callback("您输入的验证码不正确!");
      }
    },
    // 输入的验证码转换为大写的验证码
    inputCodeChange(e) {
      this.inputCodeContent = e.target.value;
      if (!e.target.value || 0 == e.target.value) {
      } else {
        this.inputCodeContent = this.inputCodeContent.toLowerCase();
      }
    },

    // 登录
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // if(!this.checked){
          //   this.$message.info('请勾选服务协议')
          //   return
          // }
          this.loginLoading = true;
          let params = Object.assign(this.loginParams, values);
          params.username = params.username.trim();
          params.password = params.password.trim();
          this.Login(params)
            .then((res) => {
              this.loginLoading = false;
              if (res.success) {
                this.$router.push("/rask");
              }
            })
            .finally(() => {
              this.loginLoading = false;
            });
        }
      });
    },

    // // 查询用户是否绑定微信
    // checkBindWxMq() {
    //   checkBindWxMq().then((res) => {
    //     // console.log(res)
    //   });
    // },

    // 同意阅读协议
    // onChange(e) {
    //   this.checked = e.target.checked;
    // },
    // userContract() {
    //   console.log(666);

    //   this.$router.push("/contract");
    //   // this.$router.push({
    //   //   // name:"Home"
    //   //   name:"Contract"
    //   // })
    // },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-input {
  height: 40px;
}
.container {
  width: 100%;
  height: 100%;
  background: url(../../assets/bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .center {
    width: 900px;
    height: 482px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .account {
      margin: 40px 90px;
      height: 402px;
      box-sizing: border-box;
      .title {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
      }
      .content {
        height: calc(100% - 68px);
        display: flex;
        justify-content: space-between;
        .wx-login {
          height: 100%;
          position: relative;
          box-sizing: border-box;
          padding-right: 40px;
          border-right: 1px solid rgba(0, 0, 0, 0.3);
          .wx-login-content {
            height: 100%;
            .wx-login-title {
              text-align: center;
              font-size: 20px;
              color: #000;
              margin-bottom: 12px;
            }
            .wx-code {
              width: 262px;
              margin: 0 auto;
              height: 262px;
              background: #fff;
            }
            .wx-login-reget {
              position: absolute;
              top: 0;
              width: 262px;
              height: 335px;
              background: rgba(255, 255, 255, 0.9);
              z-index: 60;
              display: flex;
              justify-content: center;
              align-items: center;
              .wx-login-getbtn {
                background: #3c58f2;
                width: 200px;
                height: 40px;
                font-size: 14px;
                padding: 10px;
              }
            }
            .wx-login-remark {
              text-align: center;
              font-size: 14px;
              color: gray;
              margin-top: 15px;
            }
          }
        }
        .content_right {
          margin-top: 35px;
          .common-input {
            width: 328px;
          }
          .test-code {
            position: relative;
          }
          .ccode {
            width: 328px;
            display: flex;
            justify-content: space-between;
          }
          .pint {
            cursor: pointer;
          }
          .btn {
            color: #ffffff;
            background: #3c58f2;
            border: 0;
          }
        }
      }

      // .tips {
      //   font-size: 14px;
      //   font-family: PingFangSC-Regular, PingFang SC;
      //   font-weight: 400;
      //   color: #909399;
      //   margin-bottom: 48px;
      //   text-align: center;
      //   line-height: 14px;
      // }

      // .btn-disabled {
      //   background: #d3d6dc;
      //   border: 0;
      // }
      // .proto {
      //   font-size: 12px;
      //   margin-bottom: 16px;
      //   a {
      //     color: #3c58f2;
      //   }
      // }
      // .saoyisao {
      //   width: 336px;
      //   text-align: center;
      //   color: #3c58f2;
      //   margin-bottom: 30px;
      // }
    }
  }

  // .remark-number {
  //   font-size: 14px;
  //   font-weight: 400;
  //   color: #ffffff;
  //   line-height: 14px;
  //   width: 452px;
  //   text-align: center;
  //   margin-top: 25px;
  // }
}
</style>
